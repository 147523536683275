<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>بیمه‌های نیرو‌های مسلح</h3>
      </v-card-title>
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />
      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="primary-btn submit-btn"
                  style="float: right"
                  @click="getInsurances()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <hr />
            <v-row align-v="end">
              <v-col> </v-col>
              <v-col>
                <v-btn
                  style="float: left"
                  class="secondary-btn mb-2"
                  @click="$refs.renderedExcel.$el.click()"
                  :disabled="!Items.length"
                  >دریافت اکسل</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="renderedExcel"
                  :data="Items"
                  :columns="excelFields"
                  :filename="
                    'لیست بیمه‌های نیرو‌های مسلح از ' +
                    dateFrom +
                    ' تا ' +
                    dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              empty-filtered-text="در بازه زمانی انتخاب شده بیمه‌ای برای نمایش وجود ندارد"
              :filter="Filter"
              :busy="Busy"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell(patientName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(patientLastName)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(insuranceExpirationDate)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{
                    data.item.insuranceLastSheet
                      ? "تا آخرین صفحه دفترچه"
                      : data.value
                      ? data.value
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(insurancePageNo)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(insurNo)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!--<v-dialog v-model="insuranceItemDialog" width="600">
      <v-card class="pa-3" v-if="!edited">
        <v-card-title class="mb-2">
          <h4>جزییات</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2 close-icon"
            @click="
              insuranceItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">نوع مرخصی :</span>
              <span class="h2"> {{ selected.type }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">حالت مرخصی:</span>
              <span class="h2"> {{ selected.state }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">وضعیت مرخصی :</span>
              <span class="h2"> {{ selected.status }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">ثبت شده توسط:</span>
              <span class="h2"> {{ selected.registerentName }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">تاریخ شروع :</span>
              <span class="h2"> {{ selected.startDate }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">تاریخ پایان :</span>
              <span class="h2"> {{ selected.endDate }}</span>
            </v-col>
          </v-row>
          <v-row v-if="selected.type == 'ساعتی'">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">زمان شروع :</span>
              <span class="h2"> {{ selected.startTime }}</span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="text-right">
              <span class="h2 primary--text">زمان پایان :</span>
              <span class="h2"> {{ selected.endTime }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text"> فایل مربوطه:</span>
              <a
                v-if="selected.fileId"
                class="h2 px-2"
                :href="selected.fileUrl"
                target="_blank"
                style="color: #0099cc !important"
              >
                دانلود
              </a>

              <span class="h2 px-2" v-else>فایلی وجود ندارد.</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">توضیحات :</span>
              <p class="h2 mt-1" style="line-height: 22px; text-align: justify">
                {{ selected.description }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn @click="edited = true" class="secondary-btn mb-4 me-5 pa-5">
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card v-else style="overflow-x: hidden">
        <v-card-title class="mb-2">
          <h4>ویرایش اطلاعات</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 time-row">
            <v-col>
              <v-select
                v-model="selectedEdit.type"
                :items="types"
                label="نوع مرخصی"
                outlined
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-select
                v-model="selectedEdit.state"
                :items="states"
                item-text="text"
                item-value="value"
                label="حالت مرخصی"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedEdit.status"
                :items="status"
                label="وضعیت مرخصی"
                item-text="text"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="editDateFrom">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.startDate"
                  label=" تاریخ از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.startDate"
                element="editDateFrom"
                color="#00a7b7"
              /> </v-col
            ><v-col>
              <span id="editDateTo">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.endDate"
                  label=" تاریخ تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.endDate"
                element="editDateTo"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedEdit.type == 'hourly'" class="time-row">
            <v-col>
              <span id="editTimeFrom">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="selectedEdit.startTime"
                  label=" ساعت از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.startTime"
                element="editTimeFrom"
                color="#00a7b7"
                type="time"
              />
            </v-col>
            <v-col>
              <span id="editTimeTo">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="selectedEdit.endTime"
                  label=" ساعت تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.endTime"
                element="editTimeTo"
                color="#00a7b7"
                type="time"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col
              ><div class="disabled-fields grey lighten-3">
                <span class="h3">
                  ثبت شده توسط: {{ selectedEdit.registerentName }}</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-file-input
                @change="editedUpload(file)"
                v-model="file"
                outlined
                dense
                label=" بارگذاری فایل جدید"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="myFile.showProgress"
                height="10"
                :value="myFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="myFile.isUploaded"
                  color="green"
                  class="mt-3 mb-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                v-model="selectedEdit.description"
                label="توضیحات"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn @click="editInsuranceItem()" class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn
            @click="
              edited = false;
              file = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
            "
            class="red-btn mt-4"
            >لغو</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>-->
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const MobileText = () => import("@/components/MobileText");

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "patientLastName", label: "نام‌خانوادگی بیمار" },
        { key: "insurNo", label: "شماره بیمه" },
        { key: "insuranceExpirationDate", label: "تاریخ انقضا دفترچه" },
        { key: "insurancePageNo", label: "شماره صفحه دفترچه" },
        { key: "date", label: "تاریخ و ساعت" },
      ],
      excelFields: [
        { field: "patientName", label: "نام بیمار" },
        { field: "patientLastName", label: "نام‌خانوادگی بیمار" },
        { field: "insurNo", label: "شماره بیمه" },
        { field: "insuranceExpirationDate", label: "تاریخ انقضا دفترچه" },
        { field: "insurancePageNo", label: "شماره صفحه دفترچه" },
        { field: "date", label: "تاریخ و ساعت" },
      ],
      Items: [],
      role: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      busyDocPercent: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      edited: false,
      insuranceItemDialog: false,
      selected: {},
      selectedEdit: {},
    };
  },
  methods: {
    getInsurances() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/insurances/exports/military",
          {
            startDate: this.dateFrom,
            endDate: this.dateTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editInsuranceItem() {
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leave/edit",
          {
            leaveId: this.selected.id,
            startDate:
              this.selectedEdit.type == "hourly"
                ? this.selectedEdit.startDate + this.selectedEdit.startTime
                : this.selectedEdit.startDate + "00:00",

            endDate:
              this.selectedEdit.type == "hourly"
                ? this.selectedEdit.endDate + this.selectedEdit.endTime
                : this.selectedEdit.endDate + "23:59",
            description: this.selectedEdit.description,
            status: this.selectedEdit.status,
            state: this.selectedEdit.state,
            type: this.selectedEdit.type,
            fileId: this.selectedEdit.fileId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.vLoading = false;
            this.toast(res.data, "success");
            this.edited = false;
            this.leaveItemDialog = false;
            this.selectedEdit = [];
            this.editedDateFrom = "";
            this.editedDateTo = "";
            this.editedTimeFrom = "";
            this.editedTimeTo = "";
            this.file = [];
            this.myFile.isUploaded = false;
            this.myFile.showProgress = false;

            this.$emit("getInfo");
            this.getLeaveList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
            this.edited = false;
            this.selectedEdit = [];
            this.editedDateFrom = "";
            this.editedDateTo = "";
            this.editedTimeFrom = "";
            this.editedTimeTo = "";
            this.file = [];
            this.myFile.isUploaded = false;
            this.myFile.showProgress = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.edited = false;
          this.editedDateFrom = "";
          this.editedDateTo = "";
          this.editedTimeFrom = "";
          this.editedTimeTo = "";
          this.file = [];
          this.myFile.isUploaded = false;
          this.myFile.showProgress = false;
        });
    },
    onRowSelected(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.selectedEdit = JSON.parse(JSON.stringify(item));
      this.edited = false;
      this.insuranceItemDialog = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    this.getInsurances();
  },
};
</script>
